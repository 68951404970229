<template>
  <div id="not-found">
    <MNotFound
      :title="$t('How unexpected!')"
      :subtitle="$t('Alas, there is no such page on the site. It was been removed, or perhaps never existed at all.')"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import MNotFound from 'theme/components/molecules/m-not-found';
import { Logger } from '@vue-storefront/core/lib/logger';
import { metaErrorPage } from '../meta/errors';
import { notFoundLogging } from 'theme/helpers/web-logging';
import { useDevice } from 'src/composables/useDevice';
import { isSkipSSR } from 'theme/store/checkout/helpers';

export default defineComponent({
  name: 'NotFound',
  components: {
    MNotFound
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  asyncDataManual: true,
  async asyncData ({ store, context }) {
    if (context?.server?.request?.raw?.headers) {
      const { detectDeviceFromHeaders } = useDevice()
      const serverDeviceInfo = detectDeviceFromHeaders(context?.server?.request?.raw?.headers)

      await store.dispatch('ui/setDeviceInfo', serverDeviceInfo)
    }

    if (isSkipSSR()) return

    try {
      await store.dispatch('config-varus/get', { path: ['header_logo_src'] });
      Logger.log(`Calling asyncData for NotFound page ${new Date()}`);

      if (context) {
        context.output.cacheTags.add('error');
        context.output.cacheTags.add('error:404');
        context.server.response?.status(404);
      }
    } catch (error) {
      Logger.error('Error in NotFound asyncData:', error);
      throw error;
    }
  },
  setup () {
    onMounted(() => {
      notFoundLogging();
    });

    return {};
  },
  metaInfo: metaErrorPage
});
</script>
